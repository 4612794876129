import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueTagManager from "vue-tag-manager"

Vue.use(VueTagManager, {
  gtmId: 'GTM-TZH7J6X'
})

Vue.config.productionTip = false

import AOS from 'aos';
AOS.init();

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
