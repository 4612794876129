<template>
  <div>
    <!-- Whatsapp -->
    <WaBtn />
    <!-- Header -->
    <!-- <TheHeader/> -->

    <!-- Banner -->
    <!-- <TheBanner/> -->
    <MainBanner />

    <!-- Multifunction Printer Brands  -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Multifunction <span class="text-hy-liteblue">Copier Brands</span>
        </h1>

        <div class="pt-5 md:flex">
          <div v-for="image in brands" :key="image" class="w-2/3 pb-2 mx-auto md:w-1/4">
            <img :src="image" alt="" class="mx-auto w-52" />
          </div>
        </div>
      </div>
    </div>

    <!-- machine models  -->
    <div class="py-10 bg-bottom bg-no-repeat bg-contain lg:bg-cover"
      style="background-image: url('/images/bg-product.jpg')">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Machine <span class="text-hy-liteblue">Models</span>
        </h1>

        <carousel :autoplay="false" :loop="true" :responsive="{
          0: { items: 2, nav: false },
          700: { items: 3, nav: false },
          1000: { items: 4, nav: false },
        }">
          <div v-for="(item, i) in moDels" :key="i" class="md:px-2">
            <div class="py-6">
              <img :src="item.image" :alt="item.alt" class="mx-auto text-center" />
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <!-- Photocopier Rental  -->
    <div class="py-10 bg-center bg-no-repeat bg-cover md:bg-left"
      style="background-image: url('/images/bg-photocopier.png')">
      <div class="container">
        <h1 class="text-2xl font-semibold leading-tight text-center text-white">
          Brand New & Refurbished Photocopier Available!
        </h1>
        <div class="flex flex-wrap pt-6 pb-12 lg:max-w-4xl lg:mx-auto">
          <div v-for="(item, i) in provide" :key="i" class="w-1/2 p-2 text-center md:w-1/4">
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="pt-3 text-sm leading-tight text-white">
              {{ item.alt }}
            </h1>
          </div>
        </div>

        <div class="pt-1">
          <h1
            class="py-2 mx-auto font-semibold text-center text-white transition duration-300 ease-in-out hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl">
            <a
              href="https://wa.me/60123117001?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20the%20copier%20promotion%20package,%20thank%20you">Ask
              For A Quote Now!</a>
          </h1>
        </div>
      </div>
    </div>

    <!-- Rental Package -->
    <div class="py-8 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-rental-package.jpg')">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-700">
          Find The <span class="text-hy-liteblue">Right Plan</span>
        </h1>
        <p class="pt-4 text-base leading-tight text-center text-gray-600">
          Brand New & Reconditioned Machine Available
        </p>
        <!-- packages -->
        <div class="pt-6 pb-6 lg:pt-0 md:flex md:flex-wrap lg:flex-nowrap">
          <div v-for="(item, i) in packages" :key="i" :class="item.customWidth"
            class="w-full pb-3 mx-auto text-center lg:px-1 md:p-1 lg:p-0">
            <div class="bg-white border border-gray-100 shadow-lg rounded-t-xl rounded-b-xl lg:my-6">
              <div :class="item.bgCustom" class="py-5 text-lg font-semibold text-white rounded-t-xl text-shadow">
                {{ item.most }}</div>
              <div :class="item.borderCustom" class="border-t-2 border-b-2">
                <h1 :class="item.textCustom" class="py-6 text-4xl font-bold">
                  {{ item.price }}
                </h1>
              </div>
              <div>
                <ul class="py-5 text-sm font-medium text-gray-600 md:px-2 RentCustom">
                  <li v-html="list" v-for="list in item.Package" :key="list" class="pb-1">
                    {{ list }}
                  </li>
                  <li v-if="item.image">
                    <img :src="item.image" alt="" class="w-1/2 mx-auto xl:w-44" />
                  </li>
                </ul>
              </div>
              <p class="pb-2 text-sm font-medium text-center text-red-800">
                *Terms & Conditions Apply
              </p>
              <div class="pt-6">
                <h1 v-html="item.promo" :class="item.bgCustom"
                  class="py-6 text-2xl font-bold leading-tight text-white uppercase rounded-b-xl lg:text-xl text-shadow md:flex md:items-center md:justify-center md:h-28">
                  {{ item.promo }}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <p
            class="py-2 font-semibold text-center text-white transition duration-300 ease-in-out hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl">
            <a href="https://wa.me/60123117001">WhatsApp Us</a>
          </p>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-white">
          Why Choose Us
        </h1>

        <div class="flex flex-wrap pt-10">
          <div v-for="(item, i) in choose" :key="i" class="w-1/2 pb-2 text-center md:w-1/3 lg:w-1/6">
            <img :src="item.image" :alt="item.alt" class="w-16 mx-auto" />
            <h1 class="pt-4 text-sm font-semibold text-white">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Clientele -->
    <div class="bg-white">
      <div class="container py-6 mx-auto text-center open-normal">
        <h1 class="pb-6 text-2xl font-semibold text-center text-gray-700">
          Over The Years <span class="text-hy-liteblue">We Served</span>
        </h1>
        <carousel :autoplayTimeout="1500" :autoplay="true" :loop="true" :responsive="{
          0: { items: 2, nav: false },
          700: { items: 3, nav: false },
          1000: { items: 4, nav: false },
        }">
          <div v-for="(item, i) in clienTlogo" :key="i" class="md:px-2">
            <div class="px-3 py-6">
              <img :src="item.image" :alt="item.alt" class="mx-auto text-center" />
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <!-- About Us -->
    <div class="bg-gradient-to-r from-hy-darkblue to-hy-blue">
      <div class="container py-10 mx-auto">
        <div class="lg:flex lg:items-center">
          <div class="text-white lg:w-1/2 lg:pr-4">
            <div class="pb-6 text-center lg:text-left">
              <h1 class="text-2xl font-semibold text-white">
                Who We Are
              </h1>
              <p class="pb-3 text-lg font-semibold leading-tight text-white">
                We Are Dedicated Copier Specialists
              </p>
              <p class="text-sm font-semibold text-white">
                Copier Rental | Copier Malaysia | Copier Machine | Copier Spare
                Parts| Copier Supply | Copier Recond |
              </p>
            </div>
            <p class="text-center lg:text-left">
              Hytech Office Automation (M) Sdn Bhd is a privately-owned company incorporated in 1999
              under the Companies Act 1965 of Malaysia. With 24 years of experience in this industry, we
              are proud to be one of the
              <span class="open-bold">Dedicated Copier Specialists in Malaysia.</span>
              <br /><br />
              We provide Extensive Product Range of top-quality Fuji Xerox black-and-white and colour
              photocopiers, from small and medium to high-volume capacity machines. We also represent
              other renowned brands of photocopiers, laser printer, large format printers, projectors &
              smart board such as Canon, Hewlett-Packard(HP), Epson, Panasonic and Maxhub, as well as
              our own house brand of projector screens, DP Screen
            </p>
          </div>
          <div class="pt-5 lg:w-1/2 lg:pt-0">
            <img src="/images/about-us.jpg" alt="About Us" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>

    <!-- Enquiry -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/enquiry-bg.jpg')">
      <div class="container">
        <div class="p-3 bg-hy-liteblue bg-opacity-70 lg:max-w-3xl lg:mx-auto">
          <h1 class="pt-5 pb-4 text-2xl font-semibold text-center text-white">Send An Enquiry</h1>
          <p class="pb-4 text-base font-medium text-center text-white">
            Fill out the form below and we'll get back to you as soon as possible
          </p>
          <!-- feedback.activamedia.com.sg script begins here -->
          <iframe allowTransparency="true" style="min-height: 580px; height: inherit; overflow: auto" width="100%"
            id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-5753840.html"></iframe>
          <!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <!-- sub copier banner -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-sub-2.jpg')">
      <div class="container">
        <div class="py-10 text-white lg:py-24">
          <h2 class="text-xl font-normal md:text-3xl xl:text-4xl">Looking For</h2>
          <h1 class="pt-2 text-3xl font-bold leading-tight md:text-5xl xl:text-6xl">
            Projector or <br class="hidden md:block"> Projector Screen?
          </h1>
          <div class="pt-4">
            <p
              class="p-2 text-center transition duration-200 transform rounded-md lg:w-60 lg:text-lg bg-hy-liteblue w-52 hover:scale-110">
              <a href="https://projector.hytech2u.com/">Click Here For More Info</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Contact -->
    <div class="py-10 bg-white">
      <div class="container">
        <div class="md:flex md:items-center">
          <div class="text-gray-600 md:w-1/2">
            <img src="/images/logo.png" alt="" class="mx-auto w-42">
            <div class="flex items-center pt-6">
              <i class="pr-2 text-xl text-hy-liteblue fa-solid fa-building"></i>
              <h1 class="text-lg leading-tight">Hytech Office Automation (M) Sdn Bhd</h1>
            </div>
            <div class="flex items-start pt-5">
              <i class="pr-2 text-xl text-hy-liteblue fa-solid fa-location-dot"></i>
              <h1 class="text-base leading-tight">No. 4, Jalan Sayang 3, Taman Rasa Sayang, 43200 Cheras, Selangor</h1>
            </div>
            <div class="flex items-start pt-5">
              <i class="pr-2 text-xl text-hy-liteblue fa-solid fa-phone"></i>
              <h1 class="text-base leading-tight"><a href="tel:+60123117001"> 012-3117001</a> | <a
                  href="tel:+60390802000">03-90802000</a></h1>
            </div>
            <div class="flex items-start pt-2">
              <i class="pr-2 text-xl text-hy-liteblue fa-brands fa-whatsapp"></i>
              <h1 class="text-base leading-tight"><a
                  href="https://wa.me/60123117001?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20the%20copier%20promotion%20package,%20thank%20you">
                  012-3117001</a></h1>
            </div>
          </div>
          <div class="pt-5 md:w-1/2 md:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0894815780784!2d101.75316500049833!3d3.0707633544744857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4a782d0a8b8d%3A0x15e57dd997a86490!2sHytech%20Office%20Automation%20(M)%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1607997350676!5m2!1sen!2smy"
              width="100%" height="250" frameborder="0" style="border: 0" allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Copy right -->
    <CopyRight />
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import Accordion from "@/components/Accordion.vue";
import CopyRight from "@/components/CopyRight.vue";
import carousel from "vue-owl-carousel";
import WaBtn from "@/components/WaBtn.vue"

export default {
  name: "Home",
  components: {
    carousel,
    // TheHeader,
    MainBanner,
    Accordion,
    CopyRight,
    WaBtn,
  },
  data() {
    return {
      brands: [
        "/images/brand-1.png",
        "/images/brand-2.png",
        "/images/brand-3.png",
        "/images/brand-4.png",
      ],
      moDels: [
        { image: "images/model-01.png", alt: "FujiFilm Apeos C7070" },
        { image: "images/model-02.png", alt: "ApeosPort VIIC7773" },
        { image: "images/model-03.png", alt: "Canon RUNNER ADVANCE DX C5840i" },
        { image: "images/model-04.png", alt: "Ricoh MP C3004(A)SP" },
       //{ image: "images/model-05.png", alt: "Apeosport vci 3371" },
       // { image: "images/model-06.png", alt: "Apeosport-vii-c3373" },
       // { image: "images/model-07.png", alt: "Fuji Xerox apeosport 3376" },
       // { image: "images/model-08.png", alt: " Ricoh C3003" },
      ],
      provide: [
        { image: "/images/product/icon-1.png", alt: "Sales & Rental" },
        { image: "/images/product/icon-2.png", alt: "Short Terms Rental Plan" },
        { image: "/images/product/icon-3.png", alt: "Repair & Services" },
        { image: "/images/product/icon-4.png", alt: "Trade-In Used Machine" },
        { image: "/images/product/icon-5.png", alt: "Maintenance Contracts" },
        { image: "/images/product/icon-6.png", alt: "Multifunction Printers" },
        {
          image: "/images/product/icon-7.png",
          alt: "Colour / Black & White Machine",
        },
        {
          image: "/images/product/icon-8.png",
          alt: "Brand New / Refurbished (Recond)",
        },
      ],
      packages: [
        {
          customWidth: "md:w-1/2 lg:w-1/3",
          bgCustom: "bg-gradient-to-r from-lite-pink to-dpink",
          borderCustom: "border-dpink",
          textCustom: "text-dpink",
          price: "From RM88",
          Package: [
            "Black & White",
            "Copy / Print / Scan / Fax*",
            "USB Direct Print / Scan",
            "Free Delivery",
            "Free Installation",
            "Training Provided",
            "Free Consultation",
            "Can Upgrade in future",
          ],
          promo: "Gold Plan",
        },
        {
          customWidth: "md:w-1/2 lg:w-1/3",
          most: 'Most Popular',
          bgCustom: "bg-gradient-to-r from-lite-blue to-dblue",
          borderCustom: "border-dblue",
          textCustom: "text-dblue",
          price: "From RM 98",
          Package: [
            "Colour / Black & White",
            "Copy / Print / Scan / Fax*",
            "USB Direct Print / Scan",
            "Free Delivery",
            "Free Installation",
            "Training Provided",
            "Free Consultation",
            "Can Upgrade in future",
          ],
          promo: "Platinum Plan",
        },
        {
          customWidth: "md:w-1/2 lg:w-1/3",
          bgCustom: "bg-gradient-to-r from-lite-purple to-dpurple",
          borderCustom: "border-dpurple",
          textCustom: "text-dpurple",
          price: "From RM 138",
          Package: [
            "Colour / Black & White",
            "Copy / Print / Scan / Fax*",
            "USB Direct Print / Scan",
            "Free Delivery",
            "Free Installation",
            "Training Provided",
            "Free Consultation",
            "Can Upgrade in future",
            "High Speed Heavy Duty Colour Multifunction",
          ],
          promo: "Infinite Plan",
        },
      ],
      choose: [
        { image: "/images/choose-1.png", alt: "LOWER PRICE IN TOWN " },
        { image: "/images/choose-2.png", alt: "PREMIUM QUALITY COPIERS" },
        { image: "/images/choose-3.png", alt: "EFFICIENT NETWORK SUPPORT" },
        { image: "/images/choose-4.png", alt: "21 YEARS OF EXPERTISE" },
        { image: "/images/choose-5.png", alt: "OVER 1000 SATISFIED CUSTOMERS" },
        { image: "/images/choose-6.png", alt: "FREE CONSULTATION" },
      ],
      chooseUs: [
        {
          image: "images/choose-01.png",
          alt: "Quality Assured Fuji Xerox Copiers",
        },
        { image: "images/choose-02.png", alt: "Reliable & Prompt Service" },
        { image: "images/choose-03.png", alt: "Best Bargains" },
        { image: "images/choose-04.png", alt: "21 Years of Expertise" },
        { image: "images/choose-05.png", alt: "Over 1000 Satisfied Customers" },
      ],
      clienTele: [
        { image: "images/client-01.png", alt: "Fitness First" },
        { image: "images/client-02.png", alt: "The Chicken Rice Shop" },
        { image: "images/client-03.png", alt: "EON" },
        { image: "images/client-04.png", alt: "KAO Malaysia" },
      ],
      clienTlogo: [
        { image: "images/client-01.png", alt: "Fitness First" },
        { image: "images/client-02.png", alt: "Chicken Rice Shop" },
        { image: "images/client-03.png", alt: "EON" },
        { image: "images/client-04.png", alt: "KAO" },
        { image: "images/client-05.png", alt: "Audi" },
        { image: "images/client-06.png", alt: "BABEL" },
        { image: "images/client-07.png", alt: "Celebrity Fitness" },
        { image: "images/client-08.png", alt: "CHI Fitness" },
        { image: "images/client-09.png", alt: " I REG" },
        { image: "images/client-10.png", alt: " Cambridge" },
        { image: "images/client-11.png", alt: " SASA" },
        { image: "images/client-12.png", alt: " XIXILI" },
        { image: "images/client-13.png", alt: " Etika" },
      ],
    };
  },
};
</script>

<style>
/* Font */
.poppin-semi {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.poppin-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.open-normal {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.open-semi {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.open-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

/* package */
.cusHeight {
  height: auto;
}

/* CTA */
.cuswidth {
  width: 85%;
}

/* FAQ */
.cus-maxWidth {
  max-width: 960px;
}

/* Clientele */
.owl-carousel .owl-item img {
  width: 350px !important;
}

@media (min-width: 768px) {

  /* package */
  .cusHeight {
    height: 300px;
  }

  /* CTA */
  .cuswidth {
    width: 600px;
  }

  .RentCustom {
    height: 330px;
  }
}

@media (min-width: 1024px) {

  /* enquiry form */
  .enquiry iframe {
    min-height: 520px !important;
  }
}
</style>
