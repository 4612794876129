<template>
  <div class="w-11/12 mx-auto lg:w-full">
    
    <div v-for="(item, index) in faq" :key="index" class="mb-1">
      <div class="flex flex-wrap items-center px-2 py-1 font-medium text-gray-700 cursor-pointer borderCustom" @click="makeActive(index)" ref="question">
        <p class="flex-1 open-semi">{{ item.q }}</p>
        <div class="px-2 text-2xl transition duration-200 delay-100 transform" :class="[ qIndex === index ? 'rotate-45 ' : '' ]">
          +
        </div>
      </div>

      <collapse-transition>
        <div v-show="qIndex === index ? true : false" class="px-2 py-5 text-sm transition duration-500 ease-in-out transform md:text-base open-normal">
          {{ item.a }}
          <ul class="space-y-2" v-if="item.sub1">
            <li v-for="(sub_answer1, i) in item.sub1" :key="i"><b>{{item.sPeed}}</b> {{sub_answer1}}</li>
          </ul>

          <ul class="my-5 space-y-2" v-if="item.sub2">
            <li v-for="(sub_answer1, i) in item.sub2" :key="i"><b>{{item.mulTi}}</b> {{sub_answer1}}</li>
          </ul>

          <ul class="my-5 space-y-2" v-if="item.sub3">
            <li v-for="(sub_answer1, i) in item.sub3" :key="i"><b>{{item.tyPe}}</b></li>
            <li v-for="(sub_answer1, i) in item.sub3" :key="i"><b>{{item.sTand}}</b><br> {{sub_answer1}}</li>
            <li v-for="(sub_answer2, i) in item.sub4" :key="i"><b>{{item.coLor}}</b><br> {{sub_answer2}}</li>
          </ul>
        </div>
      </collapse-transition>

    </div>
  </div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition'
export default {
  name: 'Accordion',
  props: {
    faq: {
      type: Array,
      required: true
    },
    focus: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CollapseTransition
  },
  data() {
    return {
      qIndex: 0,
    }
  },
  methods: {
    makeActive(index) {
       index === this.qIndex ? this.qIndex = -1 : this.qIndex = index
       if (this.focus) this.$scrollTo(this.$refs.question[index], 500, {offset: -80})
    }
  }
}
</script>

<style>
/* Border Style */
.borderCustom{border: 2px solid #3d5aa8;}
.borderCustom:last-child{border: none;}

</style>